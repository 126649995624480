var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8da10c25c2656901fbc6a71c5f391a676a491b7b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import * as Spotlight from "@spotlightjs/spotlight";

const environmentMap: { [key: string]: string } = {
  local: "development",
  localhost: "development",
  dev: "development",
  test: "test",
};

const environmentRegex =
  /https*:\/\/(local|localhost|dev|test|.*\.vercel\.app).*/;
const environmentMatch = environmentRegex.exec(window.location.href);
const environment = environmentMatch
  ? environmentMap[environmentMatch[1]]
  : "production";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  environment: environment,
  dsn: "https://9147d519125d84bef671a68451d5ad9d@o4506512556097536.ingest.us.sentry.io/4506914938552320",

  ignoreErrors: [
    // Errors from third-party scripts that are using up our quota
    // Exponea
    "Non-Error promise rejection captured",
    "Event `Event` (type=error) captured as promise rejection",
    "Non-Error promise rejection captured with value: Timeout",
    // cookiebot
    'null is not an object (evaluating \'cybotCookiebotDialogBodyButtonAccept.id=isMultiButton?"CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll":"CybotCookiebotDialogBodyLevelButtonAccept"\')',
    "Cannot set properties of undefined (setting 'windowOnloadTriggered')",
    "undefined is not an object (evaluating 'window.CookieConsent.ontagsexecuted')",
    // recaptcha
    "Cannot read properties of undefined (reading 'M')",
    "Cannot read properties of undefined (reading 'T')",
    // sleeknote
    "Error loading: https://sleeknotestaticcontent.sleeknote.com/production/package-core-boot.js",
    "Error loading: https://sleeknotestaticcontent.sleeknote.com/production/package-tracker.js",
    "Error loading: https://sleeknotestaticcontent.sleeknote.com/production/badge.js",
    "Error loading: https://sleeknotestaticcontent.sleeknote.com/production/package-anchored.js",
    "Error loading: https://sleeknotestaticcontent.sleeknote.com/production/package-notes.js",
    // safe to ignore
    "ResizeObserver loop limit exceeded", //https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    "ResizeObserver loop completed with undelivered notifications",
    'Object.prototype.hasOwnProperty.call(o,"telephone")', //https://github.com/getsentry/sentry/issues/61469
  ],

  // How big a percentage of the traces should be sent to Sentry 1=100%
  tracesSampleRate: process.env.SPOTLIGHT === "true" ? 1 : 0,
  // How big a percentage of sent traces should include profiling information 1=100%
  profilesSampleRate: 1,

  replaysOnErrorSampleRate: 0,

  replaysSessionSampleRate: 0,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration({
      enableInp: true,
    }),
    Sentry.browserProfilingIntegration(),
  ],
});

if (process.env.SPOTLIGHT === "true") {
  Spotlight.init();
}
